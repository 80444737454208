<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-center">
      {{props.item.id}}
    </td>
    <td class="justify-center">
      {{props.item.description}}
    </td>
    <td class="justify-center">
      <strong>{{props.item.slug}}</strong>
    </td>
    <td class="justify-center">
      <strong>{{props.item.amount | money}}</strong>
    </td> 
    <td class="justify-center">
      {{props.item.quantity_free || 0}} de {{props.item.quantity || 0}}
    </td> 
    <td class="justify-center">
      <v-btn outline small @click="$router.push({name: 'sales-list', params: {page: 'sale'}, query: {coupon_id: props.item.id}})">
        Aplicações
      </v-btn>
    </td>
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'BusinessCoupons',
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>